import Link from "next/link";

interface HeaderProps {
  currentTab: string;
}

const Header = (props: HeaderProps) => {
  return (
    <nav className="bg-white shadow  w-full" role="navigation" id="top">
        <div className="container mx-auto p-4 flex flex-wrap items-center md:flex-no-wrap">
            <div className="mr-4 md:mr-8">
                <Link href="#" >
                  <img src="/me/logo.svg" height="54" width="54" alt="Logo Carles Javierre" />
                </Link>
            </div>
            <div className="w-full md:w-auto md:flex-grow md:flex md:items-center transition-all" >
                <ul className="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:ml-auto md:mt-0 md:pt-0 md:border-0 font-mono text-lg">
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#about" title="Get to know me">About me</Link>
                    </li>
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#services" title="My offerings">Services</Link>
                    </li>
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#technologies" title="What do I work with">My tech stack</Link>
                    </li>
                    <li className="mx-4 my-2 md:my-0">
                        <div className="mx-auto">
                            <Link className="inline-block w-auto transition-all duration-150 ease-in-out bg-primary hover:shadow-lg shadow-md outline-none focus:outline-none text-white text-base py-3 px-10 rounded" href="/blog" title="Blog">Blog</Link>
                        </div>
                    </li>
                    <li>
                        <Link className="block px-4 py-1 md:p-2 lg:px-4 hover:text-primary transition-all duration-200 ease-in-out cursor-pointer" href="#contact" title="Let's talk">Contact</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>


  );
};

export default Header;